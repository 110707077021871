<template>
  <NuxtLink :to="item?.link || '/'">
    <div
      class="aspect-h-11 aspect-w-8 cursor-pointer"
      :style="{
        filter: `brightness(${data.image_brightness_filter || 100}%)`,
        backgroundColor: data.imageBackgroundColor?.hexa || 'transparent',
      }"
    >
      <UiLazyImg
        class="h-full w-full"
        :src="
          getImageByPosition(item.medias, 'list')?.file?.url ||
          '/images/no-image.webp'
        "
        :alt="imageAlt(item)"
        img-classes="object-cover"
      />
    </div>
    <h3
      class="ui-text-l mt-[15px] uppercase text-gray-dark lg:mt-6"
      :style="
        { textAlign: data?.card_content_position || 'left' } as StyleValue
      "
    >
      {{ item.currentTranslation?.title }}
    </h3>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { StyleValue } from "vue";
import { BlockEntitiesContent, BlockEntity } from "~/types/api/block.types";
import { EntityData } from "~/types/api/entities.types";

defineProps<{
  data: BlockEntitiesContent;
  item: EntityData & Pick<BlockEntity, "active" | "link">;
}>();
const { t } = useI18n();

function imageAlt(item: EntityData & Pick<BlockEntity, "active" | "link">) {
  if (getImageByPosition(item.medias, "list")?.alt) {
    return getImageByPosition(item.medias, "list")?.alt;
  } else if (item.currentTranslation?.title) {
    return `${t("image")} ${item.currentTranslation?.title}`;
  } else {
    return t("image");
  }
}
</script>
