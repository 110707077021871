<template>
  <section>
    <div
      class="container flex items-center justify-between"
      :class="{ 'mb-8 lg:mb-11': data.productView }"
    >
      <h2
        v-if="data?.title"
        class="ui-h4 relative w-full font-gt-ultra-fine text-primary"
        :style="{ textAlign: data?.title_position || 'left' } as StyleValue"
      >
        <span
          class="block whitespace-pre-wrap capitalize"
          v-html="data.title"
        ></span>
      </h2>
      <div
        v-if="!hideArrows && mappedData?.length && swiperInited"
        class="ml-auto shrink-0 max-lg:hidden"
      >
        <button
          ref="prevEl"
          :aria-label="$t('previousSlide')"
          class="mr-5 inline-flex h-[46px] w-[46px] items-center justify-center rounded-full border border-solid border-black transition-all disabled:opacity-50"
        >
          <UiIcon name="arrow_left" class="h-6 w-6 text-primary" />
        </button>
        <button
          ref="nextEl"
          :aria-label="$t('nextSlide')"
          class="inline-flex h-[46px] w-[46px] items-center justify-center rounded-full border border-solid border-black transition-all disabled:opacity-50"
        >
          <UiIcon name="arrow_right" class="h-6 w-6 text-primary" />
        </button>
      </div>
    </div>
    <ul
      class="grid gap-2 lg:hidden"
      :style="{
        gridTemplateColumns: `repeat(${data.slidesCount?.mobile || 2}, minmax(0, 1fr))`,
      }"
    >
      <li v-for="item in mappedData" :key="item.id" class="relative">
        <SharedEntityItemGridCard
          v-if="!data.productView"
          :data="data"
          :item="item as EntityData & Pick<BlockEntity, 'active' | 'link'>"
        />
      </li>
    </ul>
    <div v-if="mappedData.length" class="max-lg:hidden">
      <Swiper
        class="w-full"
        :slides-per-view="data.slidesCount?.mobile || 1.5"
        :space-between="16"
        :loop="false"
        :centered-slides="false"
        :initial-slide="0"
        :modules="modules"
        :navigation="navigation"
        :breakpoints="{
          [screens.lg]: {
            slidesPerView: data?.slidesCount?.desktop || 4,
            centeredSlides: false,
            initialSlide: 0,
            spaceBetween: spaceBetween.lg,
          },
        }"
        @swiper="initSwiper"
        @resize="onUpdateSwiper"
      >
        <SwiperSlide
          v-for="(item, idx) in mappedData.slice(0, maxItemsToShow)"
          :key="item.id"
        >
          <SharedEntityItemGridCard
            v-if="!data.productView"
            :data="data"
            :item="item as EntityData & Pick<BlockEntity, 'active' | 'link'>"
          />
          <CommonProductCardPlp
            v-else
            :product="item as CatalogData"
            :product-index="idx"
            :plp-type="plpTypes.default"
            :statistic-item-list-name="pageData?.currentTranslation?.title"
            :image-brightness-filter="data.image_brightness_filter"
            :image-background-color="data.imageBackgroundColor"
            :card-content-position="data?.card_content_position"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperInstance, Navigation } from "swiper";
import { NavigationOptions } from "swiper/types";
import { debounce } from "lodash-es";
import { StyleValue } from "vue";
import { BlockEntitiesContent, BlockEntity } from "~/types/api/block.types";
import "swiper/css/pagination";
import { EntityData } from "~/types/api/entities.types";
import { CatalogData } from "~/types/api/catalog.types";
import { plpTypes } from "~/configs";
import { screens } from "~/configs/breakpointsConfig.json";
import { PageData } from "~/types/api/pages.types";

const props = defineProps<{
  mappedData:
    | CatalogData[]
    | (EntityData[] & Pick<BlockEntity, "active" | "link">)
    | [];
  data: BlockEntitiesContent;
  maxItemsToShow: number;
  spaceBetween: any;
  pageData?: PageData | null;
}>();

const hideArrows = ref(false);
const marginButtonTop = ref(0);
const prevEl = ref<HTMLElement | null>(null);
const nextEl = ref<HTMLElement | null>(null);

const swiperInited = ref(false);

const navigation = reactive({
  prevEl,
  nextEl,
  disabledClass: "opacity-20 cursor-not-allowed hover:bg-transparent",
}) as NavigationOptions;

const modules = [Navigation];

function initSwiper(params: typeof SwiperInstance) {
  marginButtonTop.value =
    Number(params.slides[0]?.querySelector("picture > img")?.height || 100) /
      2 -
    24;

  debounceSwiper(params);
}

const debounceSwiper = debounce((params) => {
  hideArrows.value = params?.params?.slidesPerView >= props.mappedData.length;
  swiperInited.value = true;
}, 200);

function onUpdateSwiper(params: any) {
  debounceSwiper(params);
}
</script>

<style scoped>
.swiper :deep(.swiper-pagination) {
  position: absolute;
  bottom: 10px;
}

.swiper :deep(.swiper-pagination-bullet) {
  height: 10px;
  width: 10px;
  opacity: 1;
  @apply border border-white bg-transparent transition-all duration-500;
}
.swiper :deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  @apply border border-transparent bg-white;
}
</style>
